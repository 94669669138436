<template>
  <div style="height: 100%;">
    <div v-if="isGuanta(National?.fgNational ? SelectCompanyBranchId : CompanyBranchId)" style="height: 100%;">
      <Guanta
        :National="National"
        @FgFullScreenNational="$emit('FgFullScreenNational', $event)"
        @FgIntervalNationalSidebar="$emit('FgIntervalNationalSidebar', $event)"
        @isIntervalUp="$emit('isIntervalUp', $event)"
        @nationalReturn="$emit('nationalReturn', $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Guanta from "./guanta/index";

function data() {
  return {};
}

// methods
function isGuanta(id) {
  return id == process.env.VUE_APP_COMPANY_BRANCH_ID_GUANTA
}

// computed

export default {
  name: "sitacion-puerto-index",
  props: {
    National: {
      type: Object,
      default: () => {},
    },
    SelectCompanyBranchId: {
      type: String,
      default: () => '',
    },
  },
  components: {
    Guanta,
  },
  data,
  methods: {
    isGuanta,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      CompanyBranchId: (state) => state.auth.branch.CompanyBranchId,
    }),
  },
};
</script>